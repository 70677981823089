<template>
  <div ref="document">
    <div v-for="chouhyou in listChouhyou" v-bind:key="chouhyou.page" :aria-label="`${constData.chouhyouId}-${chouhyou.page}`">
      <div>
        <TemplateNormal :id="constData.chouhyouId + chouhyou.page" />
      </div>
    </div>
  </div>
</template>
<script>
import TemplateNormal from '@/assets/svg/tohoku_center/center_order/normal.svg';
   
   
   
import { setPaperA4, splitMultiRowString, splitMultiRowStringWithByte } from '@/assets/js/common';
import { listV_CENTER_HACCHU_DATA_WITH_MEISAI, getM_SHOKISETTEI } from '@/graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import html2pdf from 'html2pdf.js'
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
import { ALERT_MESSAGE_COMMON_ERROR, ALERT_TOO_LONG_SUMMARY } from "@/assets/js/dialog_messages";
export default {
  name: 'CenterOrder',
  /* コンポーネント */
  components: {
    TemplateNormal,
    
    
    
  },
  /* パラメータ */
  props:['hacchu_data_id'],
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 18,
        chouhyouId: 'idChouhyou',
      },
      // ヘッダ
      menu_type: 'user',
      title: '発注書（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      // ※height:297mmだと2ページ目にはみ出してしまうので微調整
      chouhyouSize: {
        width: '210mm',
        height: '295.01mm',
      },
      addProductCnt: 0,
      listChouhyou: [],
      replacementsCommon: [],

      //総ページ数
      pageCount: 0,

      // 備考欄の出力文字数（偶数のみ許可）
      summaryMaxLength: 30,
      // 備考が21文字以上登録されていた場合のアラートを表示済みの場合True
      tooLongSummaryNotified: false,
    }
  },
  created() {
    // ヘッダ・フッタを非表示
    this.$store.commit("setShowNav", false);
  },
  /* マウント */
  mounted() {
    // ローディングON
    this.$store.commit("setLoading", true);

    // 印刷レイアウト設定
    setPaperA4();

    this.fetchData();
  },
  /* 関数群 */
  methods:{
    async fetchData(){
      // 指定された発注データを設定
      await this.setHacchuData();
    },
    /* 発注データ設定 */
    async setHacchuData(){
      // WHERE句生成
      let whereOptions = '';
      // 発注データID
      whereOptions += `AND HACCHU_DATA_ID = ${this.hacchu_data_id} AND DENPYO_KBN = 1 `;
      // console.log(whereOptions);

      try {
        let result = await API.graphql(graphqlOperation(listV_CENTER_HACCHU_DATA_WITH_MEISAI,{where_options: whereOptions}));
        let orderData = result.data.listV_CENTER_HACCHU_DATA_WITH_MEISAI;
        // console.log({orderData});
        if(orderData && orderData.length > 0){
          // 件数からどの帳票テンプレートを用いるか、何ページ用意するか等を設定
          await this.initListChouhyou(orderData.length);
          // 共通の置換文字列設定
          await this.createReplacementsCommon(orderData);
          // ページ毎の置換文字列設定
          await this.createReplacementsPage(orderData);
          // 作成した置換文字データをSVGファイルに設定
          // await this.setChouhyou();
          const chouhyouSet = await this.setChouhyou();

          if(chouhyouSet) {
            await this.exportToPDF();
          }
        }
        // ローディングOFF
        this.$store.commit("setLoading", false);
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'setHacchuData', TYPE["0004"], error);
        // ローディングOFF
        this.$store.commit("setLoading", false);
        // アラート出力
        alert(ALERT_MESSAGE_COMMON_ERROR);
      }
    },
    /* 帳票リスト初期化 */
    async initListChouhyou(productCnt){
      // console.log('initListChouhyou');
      let productCntRemain = productCnt;
      let page = 1;
      while (productCntRemain > this.constData.cntNormal) {
        // 残りの製品件数が最終帳票の件数に収まらない場合、帳票を出力し続ける
        this.listChouhyou.push({page: page, replacements: []});
        productCntRemain -= this.constData.cntNormal;
          page++;
        }
      // 最終ページ
      this.listChouhyou.push({page: page, replacements: []});
      // 総ページ数を保持
      this.pageCount = page;
    },
    /* 共通の置換配列セット */
    async createReplacementsCommon(result){
      // console.log('createReplacementsCommon');
      const dateParts = result[0].CHUMON_DATE.split('-');
      // 作成日付
      this.replacementsCommon.push({key: '%発注日%', value: `${dateParts[0]}年${dateParts[1]}月${dateParts[2]}日`, textAnchor: 'middle', textLength: 251});
    },
    /* 製品の置換配列セット */
    async createReplacementsPage(result){
      // console.log('createReplacementsPage');
      let productsIndex = 0;
      let whiteFlg = false;
      // console.log(`listChoiuhyou.length = ${ this.listChouhyou.length}`);
      for (let i = 0; i < this.listChouhyou.length; i++) {
        // SVGファイルの置換用文字列
        let replacements = [];
        
        /* ヘッダ・フッタ */
        // 担当者名
        replacements.push({key: '%担当者名%', value: result[0].TANTOSHA_NAME ?? '', textAnchor: 'start', textLength: 183});
        // 仕入先名称
        replacements.push({key: '%仕入先名%', value: result[0].SHIIRESAKI_NAME ?? '', textAnchor: 'middle', textLength: 505});
        // 注文番号
        replacements.push({key: '%注番%', value: result[0].CHUMON_NO ?? '', textAnchor: 'start', textLength: 360});
        // 営業所情報
        await this.setOfficesData(replacements, result[0]);
        // 摘要
        this.setTekiyo(replacements, result[0].TEKIYO);

        // 帳票毎に設定可能な製品の件数
        let productCntByChouhyou = this.constData.cntNormal;

        // 製品
        for (let j = 0; j < productCntByChouhyou; j++){
          if(productsIndex < result.length){
            // 番号(帳票内の連番 + ((ページ番号-1) * 1ページあたりの件数) + 1)
            replacements.push({key: `%NO${j.toString()}%`, value: j + (this.listChouhyou[i].page -1) * this.constData.cntNormal + 1, textAnchor: 'end', textLength: 105});
            // 品名・品番
            replacements.push({key: `%品名番${j.toString()}%`, value: result[productsIndex].SHOHIN_NAME ?? '', textAnchor: 'start', textLength: 345});
            // 数量
            replacements.push({key: `%数量${j.toString()}%`, value: result[productsIndex].SURYO_SU?.toLocaleString() ?? '', textAnchor: 'end', textLength: 136});
            // 数量内訳（仙台）
            replacements.push({key: `%D${j.toString()}%`, value: result[productsIndex].SENDAI_NOHINSU_SU?.toLocaleString() ?? '', textAnchor: 'middle', textLength: 86});
            // 数量内訳（仙南）
            replacements.push({key: `%S${j.toString()}%`, value: result[productsIndex].SENNAN_NOHINSU_SU?.toLocaleString() ?? '', textAnchor: 'middle', textLength: 86});
            // 数量内訳（仙北）
            replacements.push({key: `%N${j.toString()}%`, value: result[productsIndex].SENBOKU_NOHINSU_SU?.toLocaleString() ?? '', textAnchor: 'middle', textLength: 86});
            // 納期
            let noki = '';
            if(result[productsIndex].CHAKUBI_DATE) {
              const dateParts = result[productsIndex].CHAKUBI_DATE.split('-');
              noki = `${dateParts[1].replace(/\b0+/, '')}/${dateParts[2].replace(/\b0+/, '')}`
            }
            replacements.push({key: `%V${j.toString()}%`, value: noki, textAnchor: 'middle', textLength: 92});
            // NET
            replacements.push({key: `%NET${j.toString()}%`, value: result[productsIndex].NET_GK ? result[productsIndex].NET_GK.toLocaleString() : '', textAnchor: 'end', textLength: 127});
            // 備考
            if(!this.tooLongSummaryNotified && result[productsIndex].BIKO?.length > this.summaryMaxLength) {
              // 備考欄が最大文字長を超えていたら1度だけアラートを表示
              alert(ALERT_TOO_LONG_SUMMARY);
              this.tooLongSummaryNotified = true;
            }
            this.setMeisaiSummary(replacements, j, result[productsIndex].BIKO);

            productsIndex++;
          } else {
            // 番号
            replacements.push({key: `%NO${j.toString()}%`, value: '', textAnchor: 'end', textLength: 105});
            // 数量
            replacements.push({key: `%数量${j.toString()}%`, value: '', textAnchor: 'end', textLength: 136});
            // 数量内訳（仙台）
            replacements.push({key: `%D${j.toString()}%`, value: '', textAnchor: 'middle', textLength: 86});
            // 数量内訳（仙南）
            replacements.push({key: `%S${j.toString()}%`, value: '', textAnchor: 'middle', textLength: 86});
            // 数量内訳（仙北）
            replacements.push({key: `%N${j.toString()}%`, value: '', textAnchor: 'middle', textLength: 86});
            // 納期
            replacements.push({key: `%V${j.toString()}%`, value: '', textAnchor: 'start', textLength: 92});
            // NET
            replacements.push({key: `%NET${j.toString()}%`, value: '', textAnchor: 'end', textLength: 127});
            // 備考
            for (let k = 0; k < 2; k++) {
              replacements.push({key: `%明細備考${j.toString()}-${k.toString()}%`, value: '', textAnchor: 'start', textLength: 363});
            }

            if (whiteFlg == true) {
              // 上記以外で以下余白を設定済み
              // 商品名
              replacements.push({key: `%品名番${j.toString()}%`, value: '', textAnchor: 'start', textLength: 345});
              // console.log(`空白 = ${j}`);
            } else {
              // 以下余白が入る箇所
              if ( i + 1 == this.listChouhyou.length && j + 1 == productCntByChouhyou ) {
                // 最終帳票、かつ、最終帳票の1行前の場合、余白がないため、「以上」とする。
                // 商品名
                replacements.push({key: `%品名番${j.toString()}%`, value: '以上', textAnchor: 'middle', textLength: 345});
                // console.log(`以上：j = ${j}, productCntByChouhyou = ${productCntByChouhyou}`);
              } else {
                // 商品名
                replacements.push({key: `%品名番${j.toString()}%`, value: '以下余白', textAnchor: 'middle', textLength: 345});
                // console.log(`以下余白 = ${j}, productCntByChouhyou = ${productCntByChouhyou}`);
              }
              whiteFlg = true;
            }
          }
        }
        // ページ番号
        replacements.push({key: '%ページ番号%', value: `${this.listChouhyou[i].page} / ${this.pageCount} ページ`, textAnchor: 'start', textLength: 196});

        this.listChouhyou[i].replacements = replacements;
      }
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements, meisai) {
      // 部門コード
      const bumonCd = meisai.BUMON_CD;

      // 営業所データ取得
      let result = await API.graphql(graphqlOperation(getM_SHOKISETTEI, {BUMON_CD: bumonCd}));
      // console.log({result});
      const bumon = result.data.getM_SHOKISETTEI;
      // console.log({bumonData});
      // // TODO データが存在しなかった場合の処理
      // const bumon = bumonData[0];
      // console.log({bumon});

      // 部門名称
      replacements.push({key: '%部門名%', value: bumon.BUMON_NAME ?? '', textAnchor: 'start', textLength: 455});
      // 郵便
      replacements.push({key: '%郵便%', value: bumon.YUBIN_NO ?? '', textAnchor: 'start', textLength: 229});
      // 住所
      replacements.push({key: '%住所%', value: bumon.JUSHO_NAME ?? '', textAnchor: 'start', textLength: 560});
      // TEL
      replacements.push({key: '%TEL%', value: bumon.DENWA_NO ?? '', textAnchor: 'start', textLength: 225});
      // FAX
      replacements.push({key: '%FAX%', value: bumon.FAX_NO ?? '', textAnchor: 'start', textLength: 225});
      // 発注番号
      replacements.push({key: '%発番%', value: bumon.HACCHU_NO ?? '', textAnchor: 'end', textLength: 148});

    },
    /* 置換文字列に摘要を設定 */
    async setTekiyo(replacements, summary) {
      let arySummary = splitMultiRowString(summary, 51);
      for (let i = 0; i < 5; i++) {
        if (i < arySummary.length) {
          replacements.push({key: `%摘要${i.toString()}%`, value: arySummary[i], textAnchor: 'start', textLength: 1496});
        } else {
          replacements.push({key: `%摘要${i.toString()}%`, value: '', textAnchor: 'start', textLength: 1496});
        }
      }
    },
    /* 置換文字列に備考を設定 */
    async setMeisaiSummary(replacements, rowNum, summary) {
      let arySummary = splitMultiRowStringWithByte(summary, 30);
      for (let i = 0; i < 2; i++) {
        if (i < arySummary.length) {
          // 備考
          replacements.push({key: `%明細備考${rowNum}-${i.toString()}%`, value: arySummary[i], textAnchor: 'start', textLength: 363});
        } else {
          // 備考
          replacements.push({key: `%明細備考${rowNum}-${i.toString()}%`, value: '', textAnchor: 'start', textLength: 363});
        }
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou(){
      // console.log('setChouhyou');
      for (let i = 0; i < this.listChouhyou.length; i++){
        console.log(`this.constData.chouhyouId + this.listChouhyou[i].page = ${this.constData.chouhyouId + this.listChouhyou[i].page}`);
        let svgDoc = document.getElementById(this.constData.chouhyouId + this.listChouhyou[i].page);
        this.setReplacements(svgDoc, this.listChouhyou[i].replacements);
        await this.setSize(svgDoc);
      }
      return true;
    },
    /* 帳票をPDFダウンロード */
    exportToPDF() {
      try {
        const pages = Array.from(document.querySelectorAll(`div[aria-label^="${this.constData.chouhyouId}-"]`));

        let worker = html2pdf()
          .set({
            filename: '発注書.pdf',
            image: {type: 'jpeg', quality: 0.55},
            html2canvas: { scale: 2 },
          })
          .from(pages[0])

        if (pages.length > 1) {
          // jsPDFインスタンスを生成
          worker = worker.toPdf()

          // 1ページずつPDFをレンダリング
          for (const page of pages.slice(1)) {
            worker = worker
              .get('pdf')
              .then(pdf => {
                pdf.addPage()
              })
              .from(page)
              .toContainer()
              .toCanvas()
              .toPdf()
          }
        }

        worker = worker.save()
        return true;
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'exportToPDF', TYPE["0003"], error);
        return false;
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements(node, replacements){
      // console.log('setReplacements');
      for (const textElement of node.getElementsByTagName('text')) {
        for (const child of textElement.children) {
          if(child.tagName == 'tspan'){
            for (const commonReplacement of this.replacementsCommon) {
              if(child.innerHTML.indexOf(commonReplacement.key) != -1){
                this.setTspan(textElement, child, commonReplacement);
                break;
              }
            }
            for(let i = 0; i < replacements.length; i++){
              if(child.innerHTML.indexOf(replacements[i].key) != -1){
                this.setTspan(textElement, child, replacements[i]);
                replacements.splice(i, 1);
                break;
              }
            }
          }
        }
      }
      for (const gElement of node.getElementsByTagName('g')) {
        this.setReplacements(gElement, replacements);
      }
    },
    /* Textタグ内のテキストを設定 */
    async setTspan(tagText, tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if(tagText.getBBox().width > config.textLength){
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 印鑑を設定 */
    async setStamp(tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      // 最大長を設定（最大長を超過する場合、自動で縮小）
      tagTspan.setAttribute('textLength', config.textLength);
      tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      // 座標取得
      let colX = parseFloat(tagTspan.getAttribute('x'));
      // 中央寄せ
      tagTspan.setAttribute('x', colX + config.textLength / 2);
      tagTspan.setAttribute('text-anchor', config.textAnchor);
      // 縦書きを設定（また、文字間隔を狭くする）
      tagTspan.setAttribute('writing-mode', 'tb');
      tagTspan.setAttribute('letter-spacing', -5);
    },
    /* 取得結果セット */
    async setSize(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
  },
}
</script>
<style>
  body
  {
    margin: 0;
    padding: 0;
    text-align: center;
  }
</style>